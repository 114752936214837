//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Events {
 
  list(data){
    return http.get( api.events.get.endpoint, data);
  }

  create(file,params){
    return http.post( api.events.create.endpoint+params, file ,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    } );
  }
  
  update(file,uuid,params){
    return http.put( api.events.update.endpoint+uuid+params, file ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      } );
  }
  
  delete(data){
    return http.delete( api.events.delete.endpoint+data );
  }

}

export default new Events();